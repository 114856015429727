<template>
  <div class="login w-100_ h-100_">
    <div class="login-header d-f ai-c jc-sb">
      <div class="d-f ai-c" style="    margin-top: 135px;
        z-index: 99;
    ">
        <img style="width: 32px" src="@/assets/logo.png" alt="" />
        <span>洛克侠</span>
      </div>

    </div>
    <div class="login-content-box w-100_ p-r">
      <img class="w-100_ h-100_" src="@/assets/login-content.png" alt="" />
      <div style="width: 1200px; margin: 0 auto; left: 0; right: 0" class="p-a t-0 l-0 w-100_ h-100_ d-f jc-sb">
        <div class="login-content-title ta-l">
          <div>智慧宿舍管理系统 2.0</div>
          <span>专注平安校园建设</span>
        </div>
        <div class="login-content-enter-box ta-l">
          <div class="login-content-enter-title"><span>LUOKER</span>洛克家</div>
          <div class="login-content-welcome">欢迎您，请登录！</div>
          <el-input v-model="username" placeholder="用户名/手机号"></el-input>
          <el-input v-model="password" type="password" placeholder="请输入密码"></el-input>
          <div class="d-f jc-sb c-p">
            <el-input v-model="code" style="width: 50%"> </el-input>
            <img class="c-p" @click="getCodeImage" style="height: 32px" :src="imgSrc" alt="" />
          </div>
          <div class="d-f jc-sb ai-c">
            <el-checkbox v-model="checked" text-color="#C2C3C7" fill="#C2C3C7">记住密码</el-checkbox>
            <span style="color: #f59524; font-weight: 400" class="c-p c-py fs-12" @click="forgotPass">忘记密码?</span>
          </div>
          <el-button @click="loginSubmit" type="primary" class="w-100_ login-submit-btn">登 录</el-button>
        </div>
      </div>
    </div>
    <div style="background: #ebeced">
      <div class="company-message d-f jc-sb">
        <div class="ta-l">
          <!-- <div class="fw-b">北京洛克家智能科技有限公司</div>
          <div class="c-9 fs-12" style="color: #9a9a9a">
            Beijing Lokjia Intelligent Technology Co., Ltd
          </div> -->
          <div style="margin-top: 12px" class="fs-12 c-gray">
            地址: 北京市中关村东升国际科学园
          </div>

          <div class="fs-12 c-gray">邮编: 10000</div>
        </div>

      </div>
    </div>
    <div class="footer c-9 fs-12">

      <a href="https://beian.miit.gov.cn" class="fs-12 c-gray" style="text-decoration: none;cursor: pointer;"
        target="_blank" rel="nofollow" data-v-00487b9c="">
        京ICP备17069710号-2
      </a>

      <!-- 洛克家1.1.0 All rights reserved. -->
    </div>
    <el-dialog :close-on-click-modal="false" :visible.sync="showForgotPass" custom-class="forgot-pass" width="360px"
      style="padding: 0 24px 24px 24px">
      <div slot="title" class="d-f">
        <strong style="font-size: 16px; font-weight: 600; color: rgba(0, 0, 0, 0.85)">重新发送新密码</strong>
      </div>
      <!--   <div class="ta-l" style="padding-left: 43px">
        新密码已发送到尾号{{
          phoneEnd
        }}手机上，登录后可在<b>系统设置</b>中更换手机号码
      </div>

      <div style="margin-top: 16px; padding-left: 43px">
        收不到短信？前往<span
          class="c-py c-p"
          @click="
            showAnswer = true;
            showForgotPass = false;
          "
          >回答问题找回密码</span
        >
      </div> -->
      <div class="forgot-title">
        请认真填写以下资料，为防止他人冒充，请填写真实数据通过验证找回密码
      </div>
      <el-tabs v-model="activeName">
        <!-- <el-tab-pane label="验证码" name="code"></el-tab-pane> -->
        <el-tab-pane label="身份号码" name="idNo"></el-tab-pane>
      </el-tabs>
      <el-input v-model="answerForm.schoolName" placeholder="请填写学校名称"></el-input>
      <!-- <el-input v-model="answerForm.phoneNumber" placeholder="请填写管理员电话"></el-input> -->
      <div v-if="activeName == 'code'" style="display: flex">
        <el-input v-model="answerForm.code" placeholder="请填写验证码">
        </el-input>
        <el-button style="
                margin-left: 16px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #fb8527;
                line-height: 22px;
              " type="text" slot="suffix" @click="sendCode" :disabled="!!restMiddle">
          {{ restMiddle ? restMiddle + "s" : "发送验证码" }}
        </el-button>
      </div>
      <el-input v-else v-model="answerForm.identityNo" placeholder="请填写身份证后6位"></el-input>
      <el-input v-model="answerForm.password" placeholder="请填写新密码"></el-input>
      <div style="margin-top: 24px" class="d-f ai-c jc-c btn-box">
        <el-button size="small" style="width: 88px" @click="showForgotPass = false">取 消</el-button>
        <el-button size="small" style="width: 88px; background: #fb8527" type="primary" @click="reSetPass">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="showAnswer" width="360px" custom-class="answer-box">
      <strong slot="title">回答问题找回密码</strong>
      <div class="fs-12 ta-l" style="color: rabg(0, 0, 0, 0.5); margin-bottom: 17px">
        请认真填写以下资料，为防止他人冒充，请填写真实数据通过验证找回密码
      </div>
      <!-- <el-input v-model="answerForm.phoneNumber" placeholder="请填写原手机号"></el-input> -->
      <el-input v-model="answerForm.housemaster" placeholder="请填写2-3名宿管姓名"></el-input>
      <el-input v-model="answerForm.historyPassword" placeholder="请填写历史密码"></el-input>
      <div style="margin-top: 24px" class="d-f ai-c btn-box">
        <el-button size="small" @click="showAnswer = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sure">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="showResult" custom-class="result-box" width="430px">
      <img v-if="!newPass" src="@/assets/fail.png" alt="" class="m-a" />
      <img v-else src="@/assets/success.png" alt="" class="m-a" />
      <div class="result-title">{{ newPass ? "验证成功" : "验证失败" }}</div>
      <div class="result-detail">
        {{ newPass ? "您的新密码为: " + newPass : "信息填写失败" }}
      </div>
      <div class="btn-box">
        <el-button type="primary" v-if="newPass" @click="showResult = false">前往登录</el-button>
        <el-button v-if="!newPass" @click="showResult = false">返回页面</el-button>
        <el-button type="primary" v-if="!newPass" @click="showResult = false;
        showAnswer = true;
        ">重新验证</el-button>
      </div>
    </el-dialog>
    <div class="p-a b-0 t-a w-100_" style="bottom:0;text-align:center;font-size: 12px;">

    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("login");
import ajax from "@/plugins/axios";
export function randomNum(len, radix) {
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("") + new Date().getTime();
}
import {
  setRememberItem,
  getRememberItem,
  clearRemember,
} from "./common/store";
export default {
  name: "login",
  data() {
    return {
      restMiddle: 0,
      activeName: "idNo",
      username: "",
      password: "",
      code: "",
      key: randomNum(24, 16),
      checked: false, // 保存密码
      showForgotPass: false,
      showAnswer: false, //   回答问题找回密码
      showResult: false, //  显示结果
      phoneEnd: "1166", // 尾号
      answerForm: {
        // phoneNumber: "", // 电话号码
        schoolName: "", // 宿管名称
        code: "", // 历史密码
        password: "",
        identityNo: "",
      },
      newPass: "", ///   新密码
      imgSrc: "",
    };
  },
  methods: {
    ...mapActions([
      "login",
      "getNewPass",
      "getPhoneEnd",
      "getSchoolIdByName",
      "getVerifivationCode",
      "resetPass",
      "getAllSchoolByPhone",
    ]),
    reSetPass() {
      // if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.answerForm.phoneNumber)) {
      //   this.$message.error("请输入正确的手机号码");
      //   return;
      // }
      const params = {
        username: this.answerForm.schoolName,
        // mobile: this.answerForm.phoneNumber,
        newPassword: this.answerForm.password,
      };
      if (this.activeName == "code") {
        params.verificationCode = this.answerForm.code;
      } else {
        params.identityNo = this.answerForm.identityNo;
      }
      this.resetPass(params).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message.success("修改密码成功！");
          this.showForgotPass = false;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    async sendCode() {
      if (this.answerForm.phoneNumber) {
        //dd
        if (
          !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            this.answerForm.phoneNumber
          )
        ) {
          this.$message.error("请输入正确的手机号");
          return;
        }
        if (this.restMiddle) {
          return;
        }
        const res = await this.getAllSchoolByPhone(this.answerForm.phoneNumber);
        if (!res) {
          this.$message.error("非系统管理员手机号码");
          return;
        }
        this.getVerifivationCode(this.answerForm.phoneNumber).then((res) => {
          this.restMiddle = 60;
          this.T = setInterval(() => {
            this.restMiddle = this.restMiddle - 1;
            if (this.restMiddle == 0) {
              clearInterval(this.T);
            }
          }, 1000);
        });
      } else {
        this.$message.error("请填写管理员电话号码");
      }
    },
    getCodeImage() {
      ajax
        .get(`/auth/captcha?key=${this.key}`, {
          responseType: "arraybuffer",
          needAll: true,
          isLogin: true,
        })
        .then((res) => {
          console.log(res);
          return (
            "data:image/png;base64," +
            btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )
          );
        })
        .then((res) => {
          this.imgSrc = res;
        })
        .catch(() => {
          this.$message.error("获取图片失败");
        });
    },
    forgotPass() {
      ///
      this.getPhoneEnd().then((res) => {
        this.phoneEnd = res;
        this.newPass = "";
        this.showForgotPass = true;
      });
    },
    sure() {
      ////
      this.getNewPass(this.answerForm).then(() => {
        this.newPass = Math.random() > 0.5 ? "123456" : "";
        this.showAnswer = false;
        this.showResult = true;
      });
    },
    loginSubmit() {
      const { username, password, key, code } = this;
      this.login({ username, password, key, code })
        .then(() => {
          if (this.checked) {
            setRememberItem(username, password);
          } else {
            clearRemember();
          }
          return this.getSchoolIdByName(username);
        })
        .then(() => {
          // this.$message.success("登录成功！");
          this.$router.push("/room");
        })
        .catch(() => {
          this.getCodeImage();
        });
    },
    changKeys() { },
  },
  watch: {
    showAnswer(val) {
      if (!val) {
        //  弹窗消失  清空数据
        this.answerForm = {
          // phoneNumber: "", // 电话号码
          housemaster: "", // 宿管名称
          historyPassword: "", // 历史密码
        };
      }
    },
    username(val) {
      this.answerForm.schoolName = val;
    },
  },
  computed: {},
  mounted() {
    this.getCodeImage();

    const remember = getRememberItem();
    if (remember) {
      const { username, password } = remember;
      this.username = username;
      this.password = password;
      this.checked = true;
    }
  },
};
</script>
<style >
.forgot-pass-title {
  padding: 5px;
  font-size: 16px;
}
</style>
<style lang="scss" scoped>
.login {
  .login-header {
    background: #fff;
    height: 60px;
    width: 1200px;
    padding: 0;
    color: #ff6a00;
    font-weight: bold;
    margin: 0 auto;

    img {
      margin-right: 8px;
    }
  }

  .login-content-box {
    height: 632px;

    .login-content-title {
      margin-top: 225px;
      font-size: 36px;
      height: 210px;
      color: #230a01;
      font-weight: 500;

      span {
        font-size: 17px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .login-content-enter-box {
      width: 360px;
      margin-top: 143px;
      height: 400px;
      background: #fff;
      padding: 36px 32px;

      .login-content-enter-title {
        font-size: 14px;
        color: #230a01;

        span {
          color: #ff6a00;
        }
      }

      .login-content-welcome {
        font-size: 24px;
        font-weight: 600;
        color: #242f57;
        margin: 8px 0 24px 0;
      }

      .el-input {
        margin-bottom: 16px;

        input {
          height: 32px;
        }
      }

      .login-submit-btn {
        margin-top: 24px;
      }
    }
  }

  .company-message {
    margin: 0 auto;
    width: 1200px;
    height: calc(100vh - 752px);
    min-height: 215px;
    font-size: 18px;
    font-weight: 400;
    color: #5a5a5a;
    font-size: 18px;
    padding-top: 59px;

    .c-9 {
      color: #999;
    }


  }

  .c-gray {
    color: #8c8d8d;
  }

  .footer {
    padding-top: 40px;
    height: 60px;
    // border-top: 1px solid #b6b6b7;
    background: #ebeced;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #b6b6b7;
  }
}

.answer-box {
  .el-input+.el-input {
    margin-top: 8px;
  }

  .btn-box {
    justify-content: center;

    button {
      margin: 0 8px;
      width: 88px;
    }
  }
}

.result-box {
  img {
    display: block;
    width: 80px;
    height: 80px;
  }

  .result-title {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
  }

  .result-detail {
    margin-top: 8px;
  }

  .btn-box {
    justify-content: center;
    margin-top: 24px;

    button {
      margin: 0 8px;
      width: 88px;
    }
  }
}
</style>
<style lang="scss">
.forgot-pass .el-dialog__body {
  padding: 0px 24px 24px 24px;

  input {
    margin-bottom: 8px;
    height: 36px;
  }
}

.forgot-title {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
}
</style>
